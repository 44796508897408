$background-color: #ffffff;
$primary-color: #000;
$positive-color: #00ff00;
$negative-color: #ff0000;

$button-color: #a400e1;
$button-hover-color: rgb(44, 166, 148);
$positive-button-color: rgb(46, 163, 95);

$loader-color1: black;
$loader-color2: $button-hover-color;
