@import './app/scss/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Jura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

.ace {

  /* Importing Bootstrap SCSS file. */
  @import 'node_modules/bootstrap/scss/bootstrap';

  @import './app/scss/indigo-pink.scss';
  // @import "~@angular/material/prebuilt-themes/indigo-pink.css";

  @import '@splidejs/splide/css';

}

html,
body.ace {
  height: 100%;
  overflow-x: hidden;
}

html {



  body.ace {
    margin: 0;
    font-family: 'Jura', sans-serif;
    // font-family: Roboto, "Helvetica Neue", sans-serif;
    background: $background-color;
    color: $primary-color;
  }

  .ace.bildbyran-checkout {
    font-family: 'Helvetica Neue', sans-serif !important;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: 'Helvetica Neue', sans-serif !important;
    }

    button.styled {
      background-color: #c6007e !important;
    }

    .header {
      background: #1A1A1A;
      color: white;
      padding: 20px;
      padding-top:0px;
      margin-bottom:20px;
    }

    .footer {
      background: #1A1A1A;
      color: white;
      padding: 20px;
      margin-top: 50px;

      a {
        color: white;
      }
    }


    .content {
      min-height: 90vh;
    }


    .ace-logo{
      width: 150px;
      max-width: 90%;
    }

  }


  .ace {

    &.overlayed {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 100vw;
      z-index: 9;

      .overlay {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vw;
        background: rgba(0, 0, 0, .3);
        z-index: 9;
      }
    }

    .mat-bottom-sheet-container {
      font-family: 'Jura', sans-serif;
      overflow: visible;
      max-height: 100vh;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: 'Jura', sans-serif;
    }

    .cdk-overlay-container {
      z-index: 99999;
    }

    .mat-menu-item {
      font-family: 'Jura', sans-serif;
      color: $primary-color;
      font-size: 20px;

      .mat-icon,
      .material-symbols-outlined {
        color: $primary-color;
        vertical-align: sub;
        margin-right: 5px;
      }
    }

    .mat-tab-body-content {
      overflow-x: hidden;
    }

    .mat-tab-label,
    .mat-tab-link {
      color: inherit;
    }
  }
}

.align-center {
  text-align: center;
}

table.styled {
  >tr {
    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.05);
    }

    >td,
    >th {
      padding: 10px;
    }

    &.head-row {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .inactive {
    opacity: 0.6;
  }
}

.mat-checkbox-label {
  transform: translate(-1px, 1px);
  display: inline-block;
}

input[type="checkbox"] {
  width: 100%;
  height: 100%;
}

.input-wrapper {
  margin: 0.3em 0em;

  label {
    margin-bottom: 0px;
  }

  &.large {
    font-size: 1.2em;
  }



  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  textarea {
    width: 100%;
    max-width: 300px;
    outline: 0px;
    border-width: 1px;
    border-radius: 5px;
    border-style: solid;
    appearance: none;
    background: transparent;
    padding: 0.5em 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &.limit-width {
      max-width: 500px;
    }
  }

  textarea {
    min-height: 200px;
    border-width: 2px;
  }
}

.button-row {
  margin-top: 1em;
}

.padded {
  padding: 1em;
}

button,
input[type="button"],
.button {
  &.styled {
    color: white;
    padding: .4em 2em;
    display: inline-block;
    background-color: $button-color;
    outline: 0px;
    border: 0px;
    box-sizing: border-box;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 1.3em;
    line-height: 1.5em;
    text-align: center;
    //   height: 3em;
    max-height: 100%;
    max-width: 100%;

    &.limit-width {
      max-width: 500px;
    }

    &.large {
      padding: 0em 2em;
      font-size: 1.5em;
      line-height: 1.7em;
    }

    &:disabled,
    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

  }
}

.fullwidth {
  width: 100%;
}

.fullheight {
  height: 100%;
}

.align-right {
  text-align: right !important;
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.valign-middle {
  vertical-align: middle;
}

.valign-top {
  vertical-align: top;
}

.valign-bottom {
  vertical-align: bottom;
}

.inline-block {
  display: inline-block;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

.positive-foreground {
  color: $positive-color;
}


.negative-foreground {
  color: $negative-color;
}

.spinning {
  animation: 1s spinning infinite;
}


@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}


.loader-with-text {
  display: inline-block;
  text-align: center;
  line-height: 76px;
  text-align: center;
  position: relative;
  padding: 0 48px;
  font-size: 38px;
  // font-family: Arial, Helvetica, sans-serif;
  color: black;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: currentColor;
    position: absolute;
    animation: load-with-text .7s infinite alternate ease-in-out;
    top: 0;
  }

  &:after {
    top: auto;
    bottom: 0;
  }
}



@keyframes load-with-text {
  0% {
    left: 0;
    height: 43px;
    width: 15px;
    transform: translateX(0)
  }

  50% {
    height: 10px;
    width: 40px
  }

  100% {
    left: 100%;
    height: 43px;
    width: 15px;
    transform: translateX(-100%)
  }
}



.loader {
  width: 64px;
  height: 12px;
  display: inline-block;
  background:
    radial-gradient(circle 6px, $loader-color1 100%, transparent 0),
    radial-gradient(circle 6px, $loader-color1 100%, transparent 0);
  background-size: 12px 12px;
  background-position: left center, right center;
  background-repeat: no-repeat;
  position: relative;
}

.loader:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: $loader-color2;
  inset: 0;
  margin: auto;
  animation: mvX 0.6s, mvY 0.3s;
  animation-timing-function: cubic-bezier(.5, -300, .5, 300);
  animation-iteration-count: infinite;
}

@keyframes mvX {
  100% {
    left: 0.85px
  }
}

@keyframes mvY {
  100% {
    top: 0.5px
  }
}
